<template>
  <div class="box">
    <el-empty :image="src" :description="'暂无' + text" :image-size="100"></el-empty>
  </div>
</template>
<script>
export default {
  name: "MyEmpty",
  props: {
    //
    text: {
      type: String,
      default: "内容",
    },
  },
  data() {
    return {
      src: require("@/assets/img/empty.png"),
      // desc: "暂无" + this.text,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
/deep/.el-empty__description p {
  margin: 0;
  font-size: 15px !important;
  color: #7991cb !important;
}
.box {
  width: 100%;
  margin-top: 100px;
}
</style>
