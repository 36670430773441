<!-- 知识库 -->
<template>
  <div class="bg container">
    <div class="div_left">
      模<br>
      型<br>
      列<br>
      表
    </div>
    <div class="conBox">
      <div class="dfc" style="height: 80px">
        <div class="dfc">
          <el-button class="dfc_btn" type="primary" size="small" plain @click="dialogVisible = true">添加</el-button>
        </div>
      </div>

      <div class="oratory">
        <div class="show_data">
          <div class="listBox df" v-if="list.length > 0">
            <div class="item cp df" v-for="i in list" :key="i.id" @click="toDetail(i)">
              <img class="icon" src="@/assets/img/ckwa2.png" alt="" />
              <div class="item_info">
                <div class="ml20 info">
                  <div class="dfc mb10">
<!--                    <div style="overflow: hidden;max-width: 162px;max-height: 20px;">-->
                      <span class="name">{{ i.kb_name }}</span>
<!--                    </div>-->
                    <el-button style="margin-right: -28px;" class="come_btn"  type="primary" size="small" @click.stop="toDetail(i)">上传文档</el-button>
                    <div class="ctrl">
                      <img style="border: none !important;" class="cp" src="@/assets/img/bianji.png" alt="" @click.stop="edit(i)" />
                      <img style="border: none !important;" class="cp" src="@/assets/img/shanchu.png" alt="" @click.stop="del(i)" />
                      <img style="border: none !important;" class="cp" src="@/assets/img/shezhi.png" alt="" @click.stop="editRelated(i)" />
                    </div>
                  </div>
                  <div class="dfc mt15" style="margin-top: 20px">
                    <div class="time">
                      {{ i.kb_id }}
                        <el-button style="margin-left: 60px;" class="come_btn"  type="primary" size="small" @click.stop="toChat(i)">进入对话</el-button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="emptyBox" v-else>
            <mine-empty></mine-empty>
          </div>
        </div>

        <el-dialog title="设置回复精准度" :visible.sync="dialogEdit" width="30%" :destroy-on-close="true">
          <div class="block dfc">
            <span class="blodText" style="color: white!important;">精准度：</span>
            <el-slider style="width: 75%;color: white!important;" v-model="related" :max="20" :step="0.1"></el-slider>
            <span class="demonstration" style="color: white!important;">{{ related }}</span>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button class="cancel_btn" @click="dialogEdit = false">取 消</el-button>
            <el-button class="submit_btn" type="primary" @click="setKnowledge">提 交</el-button>
          </span>
        </el-dialog>
<!--添加模型-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :destroy-on-close="true">
          <el-input v-model="ipt" placeholder="请输入模型名称"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button
              @click="
                dialogVisible = false;
                ipt = '';
              "
              class="cancel_btn">取 消</el-button
            >
            <el-button class="submit_btn" type="primary" @click="addItem">提 交</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { get_list_knowledge_base, add_knowledge_base, edit_knowledge_base, del_knowledge_base, get_Knowledge, set_Knowledge } from "@/api/miniature.js";
import MineEmpty from "@/components/MineEmpty.vue";

export default {
  name: "interaction",
  components: {
    MineEmpty,
  },
  data() {
    return {
      search: "",
      user_id: localStorage.getItem("token"),
      localhosts: this.$onlyId,
      title: "添加模型",
      dialogVisible: false,
      dialogEdit: false,
      dialogType: "add",
      ipt: "",
      id: "",
      related: "",
      list: [],
      pageInfo: { total: 1, page: 1, item: 999 },
    };
  },
  mounted() {
    this.user_id = localStorage.getItem("token");
    // this.user_id = "65ae2d37988636da8c0eaa392d6db715";
    console.log("info", this.localhosts);
    this.getData();
  },
  methods: {
    async getData() {
      const loading = this.$loading({
        lock: true,
        text: "正在获取模型列表，请等待",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let { data } = await get_list_knowledge_base({
        user_id: this.user_id,
        localhosts: this.localhosts,
      });
      loading.close();
      if (data.code == 200) {
        this.list = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    toDetail(i) {
      this.$router.push({ name: "knowledgeDetail", params: { detail: i } });
    },
    toChat(i) {
      this.$router.push({ name: "knowledgeChat", params: { detail: i } });
    },
    async addItem() {
      if (!this.ipt) {
        this.$message.error("请输入模型名称");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在保存模型，请等待",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = "";
      if (this.dialogType == "add") {
        res = await add_knowledge_base({
          user_id: this.user_id,
          kb_name: this.ipt,
          localhosts: this.localhosts,
        });
      } else {
        res = await edit_knowledge_base({
          user_id: this.user_id,
          new_kb_name: this.ipt,
          localhosts: this.localhosts,
          kb_id: this.id,
        });
      }
      loading.close();
      if (res.data.code == 200) {
        this.$message.success(res.data.msg);
        this.dialogVisible = false;
        this.dialogType = "add";
        this.ipt = "";
        this.title = "添加模型";
        this.getData();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    edit(i) {
      this.dialogType = "edit";
      this.title = "编辑模型名称";
      this.ipt = i.kb_name;
      this.id = i.kb_id;
      this.dialogVisible = true;
    },
    async editRelated(i) {
      this.id = i.kb_id;
      const loading = this.$loading({
        lock: true,
        text: "正在保存设置，请等待",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await get_Knowledge({
        kb_id: this.id,
      });
      loading.close();
      if (res.code == 200) {
        this.related = res.data.score * 10;
      }
      this.dialogEdit = true;
    },
    async setKnowledge() {
      let res = await set_Knowledge({
        kb_id: this.id,
        score: this.related / 10,
      });
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.dialogEdit = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    del(i) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: 'my-custom-confirm',
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: "正在删除模型列表，请等待",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let { data } = await del_knowledge_base({
            user_id: this.user_id,
            localhosts: this.localhosts,
            kb_ids: i.kb_id,
          });
          loading.close();
          if (data.code == 200) {
            this.$message.success(data.msg);
            this.getData();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.cancel_btn{
  width: 68px;
  height: 41px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(166, 166, 166, 1);
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 20px 5px 20px;
  color: rgba(56, 56, 56, 1);
  border: none;
}
.submit_btn{
  width: 68px;
  height: 41px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(42, 130, 228, 1);
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 20px 5px 20px;
  border: none;
}
::v-deep{
  .el-message-box__header{
    background: black !important;
  }
}
::v-deep{

  .el-input__inner{
    width: 787px;
    height: 219px;
    opacity: 1;
    border-radius: 15.55px;
    background: rgba(28, 28, 36, 1) !important;
    border: none;
    color: white;
    font-size: 20px;
  }
  .el-dialog{
    border-radius: 10px !important;
    width: 872px !important;
    height: 445px;
    opacity: 1;
    background: rgba(31, 37, 49, 1) !important;
  }
  .el-dialog__header{
    text-align: left;
    border-radius: 8px 8px 0px 0px;
    background: rgba(44, 50, 64, 1);
    span{
      color: rgba(11, 224, 178, 1);
    font-size: 18px;
    font-weight: 700;
      text-align: left;
    }
  }
}
.item{
  width: 46.5% !important;
  opacity: 1;
  border-radius: 10px !important;
  background: rgba(21, 201, 166, 0.1) !important;
  border: 2px solid rgba(21, 201, 166, 1);
  /*display: flex;*/
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 12px 15px 12px;
  margin-bottom: 50px !important;
  margin-right: 40px !important;
}
.item_info{
  width: 30.75rem;
  height: 97px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 5px 5px 5px;
  margin-left: 60px;
  .ctrl{
    float: right;
    margin-right: 90px;
  }
  .cp{
    margin-right: 20px;
  }
  .come_btn{
    float:right;
    margin-right: -30px;
    width: 76px;
    height: 25px;
    opacity: 1;
    border-radius: 27px;
    background: linear-gradient(90deg, rgba(80, 139, 222, 1) 30%, rgba(180, 255, 18, 0) 100%) !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2px 10px 2px 10px;
    border: none !important;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;

  }
  .name{
    width: 162px;
    overflow: hidden;
    /** 文本1 */
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 0px;
    line-height: 23.17px !important;
    color: rgba(255, 255, 255, 1) !important;
    text-align: left;
    vertical-align: top;
  }
}
.div_left{
  float: left;
  left: 81px;
  top: 52px;
  width: 53px;
  height: 52.4375rem;
  opacity: 1;
  border-radius: 45px;
  background: rgba(44, 50, 64, 1);
  box-shadow: 0px 3px 12px  rgba(1, 26, 21, 1);
  color: rgba(21, 201, 166, 1);
  padding: 23.3125rem 0px;
  position: relative;

  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24.62px;
}
.dfc_btn{
  width: 68px;
  height: 41px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(11, 224, 178, 1) !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  padding: 12px 18px;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: black !important;
  margin-left: 84.125rem;
  margin-top: 10px;
}
.el-button--primary.is-plain{
  border: none !important;
}
.conBox {
  position: relative;
  float: left;
  left: 8.125rem;
  top: 1.26rem;
  width: 92.5625rem;
  height: 742px;
  opacity: 1;
  border-radius: 15px;
  background: rgba(44, 50, 64, 1);
  box-shadow: 0px 3px 10px  rgba(1, 26, 21, 1);
  margin: 20px;
  /*background: #fff;*/
  padding: 30px;
  min-height: 87vh;
  overflow-y: scroll;
  /*border-radius: 8px;*/

  .cHead {
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 700;
    font-size: 16px;
    color: #202020;
    line-height: 19px;
    text-align: left;
  }
}
.line {
  height: 1px;
  margin: 15px 0;
  border-bottom: 1px solid #e8e8e8;
}

.listBox {
  padding-top: 20px;
  max-height: 780px;
  overflow-y: scroll;
  justify-content: space-between;
  flex-wrap: wrap;
  .item {
    width: 49%;
    height: 140px;
    padding: 28px 25px;
    border-radius: 8px;
    // border: 1px solid #eaedf0;
    margin-bottom: 15px;
    background: #f2f7fe;

    .info {
      width: 90%;
      text-align: left;
    }

    .icon {
      width: 80px;
      height: 80px;
      border-radius: 6px;
    }

    .name {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #252525;
      line-height: 24px;
    }

    .ctrl {
      width: 100px;
      display: flex;
      justify-content: space-between;

      img {
        width: 30px;
        height: 30px;
        padding: 5px;
        border: 1px solid #d3d3d3;
        border-radius: 4px;
      }
    }

    .time {
      /** 文本1 */
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 23.17px;
      color: rgba(177, 255, 8, 0.5);
      text-align: left;
      vertical-align: top;

    }
  }
}
::v-deep{
  .bg{
    background: rgba(31, 37, 49, 1);
  }
}
::v-deep {
  .el-icon-plus,
  .el-icon-chat-line-square:before {
    font-size: 15px;
  }
}
</style>
